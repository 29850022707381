import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const PageTemplate = ({
  content,
  contentComponent,
  title,
  helmet,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="page">
      {helmet || ""}
      <h1 className="title container-md">{title}</h1>
      <section className="container-md">
        <PageContent content={content} />
      </section>
    </section>
  );
};

PageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const Page = ({ data }) => {
  const { markdownRemark: post } = data;
  const title = post.frontmatter.title || null;
  const description = post.frontmatter.description || post.excerpt || null;

  console.log(data, post)
  return (
    <Layout>
      <PageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet>
            {title ? <title>{title}</title> : null}
            {description ? <meta name="description" content={description} /> : null}
            {title ? <meta property="og:title" content={title} /> : null}
          </Helmet>
        }
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default Page;

export const pageQuery = graphql`
  query PageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`;
